<template>
  <div>
    <SweetModal
      ref="modal"
      overlay-theme="dark"
      width="90%"
      hide-close-button
      blocking
    >
      <template #title>
        <b-row class="d-flex" align-h="around">
          <b-col>
            <span>
              Criação rápida de <strong>{{ normalizedType }}</strong>
            </span>
          </b-col>
          <b-col>
            <span>
              Para a aplicação <strong>{{ application }}</strong>
            </span>
          </b-col>
          <b-col>
            <span>
              Tipo de Notificação
              <strong>{{ notification }}</strong>
            </span>
          </b-col>
        </b-row>
      </template>
      <b-row class="mb-4">
        <template
          v-if="optionalVariables.length > 0 || requiredVariables.length > 0"
        >
          <b-col class="d-flex align-items-baseline">
            <ListTags
              v-if="optionalVariables.length > 0"
              :variables="optionalVariables"
              :element="'optionalVariables'"
              :title="'Tags Opcionais'"
            />
          </b-col>
          <b-col class="d-flex align-items-baseline">
            <ListTags
              v-if="requiredVariables.length > 0"
              :variables="requiredlVariables"
              :element="'requiredVariables'"
              :title="'Tags Obrigatórias'"
            />
          </b-col>
        </template>
        <div v-else>Como usar variáveis:</div>
      </b-row>
      <b-row> </b-row>

      <MailEditor v-if="creating" ref="quick_template_editor" />
      <template #button>
        <b-button class="mr-2" @click="close" variant="success"
          >Cancelar</b-button
        >
        <b-button @click="save_template" variant="success">Salvar</b-button>
      </template>
    </SweetModal>
    <SweetModal
      ref="modalError"
      overlay-theme="dark"
      hide-close-button
      icon="error"
    >
      Erro ao gravar template
    </SweetModal>
    <SweetModal
      ref="modalSuccess"
      overlay-theme="dark"
      hide-close-button
      icon="success"
    >
      Template salvo com sucesso
    </SweetModal>
    <ModalInvalidVariables ref="modalInvalidVariables" />
  </div>
</template>

<script>
export default {
  props: {
    application_id: {
      type: String,
      default: null,
    },
    notification_type_id: {
      type: String,
      default: null,
    },
    type: { type: String },
  },
  data() {
    return {
      creating: false,
      model: {
        id: '',
        application_id: this.application_id,
        unity_id: this.$store.state.auth.currentUser.unity_id,
        notification_type_id: this.notification_type_id,
        type: this.type,
        variables: "",
        design: "",
        html: "",
        active: "1",
      },
    };
  },
  components: {
    MailEditor: () => import("@/components/EmailEditor"),
    ListTags: () => import("@/components/ListTags"),
    ModalInvalidVariables: () => import("@/components/ModalInvalidVariables"),
  },
  watch: {
    application_id: {
      handler(newValue) {
        this.model.application_id = newValue;
      },
    },
    notification_type_id: {
      handler(newValue) {
        this.model.notification_type_id = newValue;
      },
    },    
  },
  computed: {
    state() {
      return this.$store.state.global;
    },
    applications() {
      return this.state.applications;
    },
    applicationsById() {
      return _.keyBy(this.applications, "id");
    },
    application() {
      if (this.application_id == null) {
        return "Todas aplicações";
      } else {
        return this.applicationsById[this.application_id].name;
      }
    },
    notificationTypes() {
      return this.state.notificationTypes;
    },
    notificationTypesById() {
      return _.keyBy(this.notificationTypes, "id");
    },
    notification() {
      if (this.notification_type_id == null) {
        return "Personalizado";
      } else {
        return this.notificationTypesById[this.notification_type_id]
          .description;
      }
    },
    optionalVariables() {
      let variables = [];
      const notificationType =
        this.notificationTypesById[this.notification_type_id];
      if (notificationType) {
        variables = notificationType.variables.split(",");
        if (notificationType.required_variables) {
          const required = notificationType.required_variables.split(",");
          variables = [...variables, ...required];
        }
      }
      return variables;
    },
    requiredVariables() {
      return [];
    },
    normalizedType() {
      return this.type == "header" ? "Cabeçalho" : "Rodapé";
    },
  },
  methods: {
    close() {
      this.$emit("closed");
      this.creating = false
      this.$refs.modal.close();
    },
    open() {
      this.model.id = this.get_uuid()
      this.creating = true
      this.$refs.modal.open();
    },
    async save_template() {      
      const submit = async () => {
        this.model.variables = this.get_variables(this.model.html).join(",");

        const response = await this.$store.dispatch(
          "global/save_template",
          this.model
        );
        console.log(response)
        if (response) {
          this.$refs.modal.close();
          this.$refs.modalSuccess.open();
        } else {
          this.$refs.modalError.open();
        }
      };
      const { html, design } =
        await this.$refs.quick_template_editor.save_design();
      this.model.html = html;
      this.model.design = JSON.stringify(design);
      if (this.notification_type_id) {
        const { invalid, required } = this.check_tag(
          this.model.html,
          this.optionalVariables,
          this.requiredVariables
        );
        if (invalid.length > 0 || required.length > 0) {
          this.$refs.modalInvalidVariables.open(required, invalid);
        } else {
          submit();
        }
      } else {
        submit();
      }
    },
  },
  mounted(){
    console.log('editor mounted')
  }  
};
</script>

<style>
</style>